<template>
  <b-row>
    <b-col cols="12">
      <div v-if="loading">
        <b-button class="mb-3" :to="{name: 'reports'}" variant="primary"><fa-icon class="mr-1" icon="arrow-left" /> Reports</b-button>
        <div class="text-center">
          <b-spinner variant="primary"></b-spinner>
          <p class="mt-2 h6">Loading...</p>
        </div>
      </div>
      <div v-if="!loading && values">
        <div class="d-flex flex-row mb-3">
          <b-button class="mr-1" :to="{name: 'reports'}" variant="primary"><fa-icon class="mr-1" icon="arrow-left" /> Reports</b-button>
          <b-button class="mr-1" :href="csvLink" variant="light"><fa-icon class="mr-1" icon="download" /> CSV</b-button>
          <div class="justify-content-end d-flex flex-row flex-fill">
            <b-input-group style="max-width: 700px;">
              <b-form-datepicker v-model="start" />
              <b-input-group-text class="border-left-0 border-right-0"> - </b-input-group-text>
              <b-form-datepicker v-model="end" />
            </b-input-group>
          </div>
        </div>
        <b-table bordered responsive striped head-variant="dark" :fields="fields" :items="values" v-if="!loading && values">
          <template #cell(name)="{ item }">
            {{ item.property && item.property.name }}
          </template>
          <template #cell(outstanding_revenue)="{ item }">
            {{ item.outstanding_revenue ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.outstanding_revenue) : '£0.00' }}
          </template>
          <template #cell(revenue)="{ item }">
            {{ item.revenue ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.revenue) : '£0.00' }}
          </template>
          <template #cell(profit)="{ item }">
            {{ item.profit ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.profit) : '£0.00' }}
          </template>
        </b-table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      loading: false,
      values: {},
      fields: [
        { key: 'name', text: 'Property' },
        { key: 'bookings', text: 'Bookings' },
        { key: 'nights', text: 'Nights' },
        { key: 'avg_nights', text: 'Avg. Nights' },
        { key: 'outstanding_revenue', text: 'Outstanding Revenue' },
        { key: 'revenue', text: 'Revenue' },
        { key: 'profit', text: 'Profit' },
        { key: 'guests', text: 'Guests' },
        { key: 'avg_guests', text: 'Avg. Number of Guests' },
      ],
      start: this.moment().add(-1, 'month').startOf('month').toDate(),
      end: this.moment().add(-1, 'month').endOf('month').toDate(),
    };
  },
  async mounted() {
    await this.load();
  },
  computed: {
    csvLink() {
      return `/api/reports/occupancy.csv?start=${this.moment(this.start).format('YYYY-MM-DD')}&end=${this.moment(this.end).format('YYYY-MM-DD')}`;
    }
  },
  methods: {
    moment,
    async load() {
      this.loading = true;

      const response = await window.apiFetch(`/reports/occupancy?start=${this.moment(this.start).format('YYYY-MM-DD')}&end=${this.moment(this.end).format('YYYY-MM-DD')}`);
      this.values = await response.json();
      this.loading = false;
    }
  },
  watch: {
    start() {
      return this.load();
    },
    end() {
      return this.load();
    },
  }
}
</script>
